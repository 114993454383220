import { AppConsts } from '@shared/AppConsts';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';

export class SubdomainTenancyNameFinder {

    getCurrentTenancyNameOrNull(rootAddress: string): string {

        if (rootAddress.indexOf(AppConsts.tenancyNamePlaceHolderInUrl) < 0) {
            // Web site does not support subdomain tenant name
            return null;
        }

        let strHref = document.location.href;
        strHref = strHref.replace('https', 'http');

        let strRootAddress = rootAddress;
        strRootAddress = strRootAddress.replace('https', 'http');

        const currentRootAddress = document.location.href;        

        const formattedStringValueExtracter = new FormattedStringValueExtracter();
        const values: any[] = formattedStringValueExtracter.IsMatch(strHref, strRootAddress);

        console.log(values, 'values', currentRootAddress, rootAddress, strHref, strRootAddress);

        if (!values.length) {
            return null;
        }

        return values[0];
    }

}
