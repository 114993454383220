import { Component, Injector, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Sys_ChatMessageDto, Sys_ChatRoomDto, Sys_ChatRoom_UserDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'sys-chat-message-inbox',
    styleUrls: ['./sys-chat-message-inbox.component.scss'],
    templateUrl: './sys-chat-message-inbox.component.html',
})
export class SysChatMessageInboxComponent extends AppComponentBase implements OnInit {
    arrDataRoom: Sys_ChatRoomDto[] = [];
    constructor(
        injector: Injector,
        private router: Router,
        private afs: AngularFirestore
    ) {
        super(injector);
    }

    ngOnInit() {
        this.fetchDataChatRoomList();

    }

    fetchDataChatRoomList(): void {
        this.afs.collection('sysChatRoom').valueChanges()
            .subscribe((res: Sys_ChatRoomDto[]) => {
                //console.log(res, 'data SysChatRoom');
                let data = res.filter(m => {
                    let userIds = JSON.parse(m.userIdsJson);
                    if (userIds.includes(this.appSession.userId) && m.lastMessageJson) {
                        return m;
                    }
                });
                this.arrDataRoom = data.sort(function (a, b) {
                    let dateA = new Date(a.dateCreate + '').getTime();
                    let dateB = new Date(b.dateCreate + '').getTime();
                    if (a.lastMessageJson) {
                        let lastMessageA = JSON.parse(a.lastMessageJson) as Sys_ChatMessageDto;
                        dateA = new Date(lastMessageA.messageDate).getTime();
                    }
                    if (b.lastMessageJson) {
                        let lastMessageB = JSON.parse(b.lastMessageJson) as Sys_ChatMessageDto;
                        dateB = new Date(lastMessageB.messageDate).getTime();
                    }
                    return dateA < dateB ? 1 : -1;
                });

                //console.log(this.arrDataRoom, 'data this.arrDataRoom');
            });
    }

    totalUnread() {
        if (this.arrDataRoom && this.arrDataRoom.length > 0) {
            let total = this.arrDataRoom.filter(m => {
                let data = JSON.parse(m.chatRoom_UserJson) as Sys_ChatRoom_UserDto[];
                return !data.find(m => m.userId == this.appSession.userId)?.isFinishedRead;
            }).length;
            return total;
        }
        return 0;
    }

    getRoomName(item: Sys_ChatRoomDto) {
        if (item) {
            let chatRoom_Users = JSON.parse(item.chatRoom_UserJson) as Sys_ChatRoom_UserDto[];
            return chatRoom_Users.find(m => m.userId != this.appSession.userId).name;
        }
        return '';
    }

    hasReadRoomMessage(item: Sys_ChatRoomDto) {
        if (item && item.chatRoom_UserJson) {
            let data = JSON.parse(item.chatRoom_UserJson) as Sys_ChatRoom_UserDto[];
            return data.find(m => m.userId == this.appSession.userId)?.isFinishedRead;
        }
        return false;
    }

    getLastMessage(item: Sys_ChatRoomDto): Sys_ChatMessageDto {
        if (item.lastMessageJson != null) {
            let lastMessage = JSON.parse(item.lastMessageJson) as Sys_ChatMessageDto;
            return lastMessage;
        }
        return null;
    }

    viewDetailChat(item: Sys_ChatRoomDto){
        this.router.navigate(['app/chat-management'],{
            queryParams:{
                id: item.id
            }
        })
    }

    viewChatAll() {
        this.router.navigate(['app/chat-management'])
    }

}
