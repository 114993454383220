import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({providedIn: 'root'})
export class AppChangeLayoutStateService {
    isPinSidebar$ = new BehaviorSubject(false);

    setLayoutSubjectPatchValue(value: boolean) {
        this.isPinSidebar$.next(value);
    }
}
