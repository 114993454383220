import { Pipe, PipeTransform } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { CourseOrderType } from '@shared/service-proxies/service-proxies';

@Pipe({
    name: 'indexToOrderChar',
    pure: true
})
export class IndexToOrderCharPipe implements PipeTransform {

    transform(value: any, type?: number): any {

        if (value) {
            if (type == CourseOrderType.RomanNumbering) {
                return this.romanize(value);
            }
            else if (type == CourseOrderType.NumberedAlphabetically) {
                return AppUtilityService.getAlphabetByIndex(value-1);
            }
            return value + 1;
        }
        return value;
    }


    romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }


}
