import { AbpMultiTenancyService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ApplicationInfoDto, GetCurrentLoginInformationsOutput, ROLE_LEVEL, SessionServiceProxy, TenantLoginInfoDto, UserLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { SessionStorageKey } from '@app/shared/common/AppConsts';

@Injectable()
export class AppSessionService {

    private _user: UserLoginInfoDto;
    private _tenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;

    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService) {
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenancyName(): string {
        return this._tenant ? this.tenant.tenancyName : '';
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    getShownLoginName(): string {
        const userName = this._user?.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }

        return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }

    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._sessionService.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;
                if (this._tenant) {
                    localStorage.setItem('cacheTenancyName', this._tenant.tenancyName);
                }

                //showhide menu by role
                this.showHideMenuByRole();

                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }

    showHideMenuByRole() {
        if (this._user) {
            let keyCurrentRoleLevel = SessionStorageKey.keyCurrentRoleLevel + "-" + this._user.id;
            let checkSession = sessionStorage.getItem(keyCurrentRoleLevel);
            if (!checkSession) {
                if (this._user.isRoleAdmin) {
                    sessionStorage.setItem(keyCurrentRoleLevel, ROLE_LEVEL.QuanTriVien.toString());
                }
                else if (this._user.isRoleLecturers) {
                    sessionStorage.setItem(keyCurrentRoleLevel, ROLE_LEVEL.GiangVien.toString());
                }
                else if (this._user.isRoleLecturers) {
                    sessionStorage.setItem(keyCurrentRoleLevel, ROLE_LEVEL.HocVien.toString());
                }
            }
        }
    }
    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        let isTenant = tenantId > 0;

        if (!isTenant && !this.tenant) { // this is host
            return true;
        }

        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }
}
