import { ElementRef, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalHelper, TitleService } from '@delon/theme';
import { AppConsts } from '@shared/AppConsts';
import { AbpMultiTenancyService, FeatureCheckerService, LocalizationService, MessageService, NotifyService, PermissionCheckerService, SettingService } from 'abp-ng2-module';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import * as $ from 'jquery';
import { ComboBoxTableCode } from '../service-proxies/service-proxies';
import { AppUrlService } from './nav/app-url.service';
import { AppSessionService } from './session/app-session.service';

export abstract class AppComponentBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    getWidthMobile = AppUtilityService.getWidthMobile;
    getHeightMobile = AppUtilityService.getHeightMobile;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    appUrlService: AppUrlService;
    modalHelper: ModalHelper;
    protected titleSrv: TitleService;
    activatedRoute: ActivatedRoute;
    hotkeysService: HotkeysService;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.appUrlService = injector.get(AppUrlService);
        this.modalHelper = injector.get(ModalHelper);
        this.titleSrv = injector.get(TitleService);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.hotkeysService = injector.get(HotkeysService);

        //HostKey
        this.initHotkeys();

        this.activatedRoute.url.subscribe(() => {
            this.hotkeysService.remove();
            this.createHotkeys();
        });
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    refreshData(arrField, obj) {
        arrField.forEach(item => {
            obj[item] = null;
        });
    }

    getCodeValue(dataItem: any, dataField: string, value: any, isMulti?: boolean) {
        if (isMulti) {
            if (value != null) {
                dataItem[dataField] = value;
            }
            else {
                dataItem[dataField] = null;
            }
        }
        else {
            if (value != null) {
                dataItem[dataField] = value.displayText;
            }
            else {
                dataItem[dataField] = null;
            }
        }
    }

    convertTextAreaToHtml(string) {
        if (string) {
            return abp.utils.replaceAll(string, "\n", "</br>")
        }
    }

    convertHtmlToText(html) {
        return AppUtilityService.convertHtmlToText(html);
    }

    cutString(txt, maxLength) {
        if (txt && txt.length > maxLength) {
            return txt.substring(0, maxLength) + "...";
        }
        else {
            return txt;
        }
    }

    trackByIndex(index: number, el: any): number {
        if (el && el.id) {
            return el.id;
        }

        return index;
    }

    //#region UX_HotKeys

    hotkeys: Hotkey[] = [];
    initHotkeys() {
        this.hotkeys = [];
        this.hotkeys.push(new Hotkey('f3', (): boolean => {
            $('.txtSearch').focus();
            return false;
        }, ['INPUT', 'TEXTAREA', 'SELECT']));
    }

    createHotkeys() {
        this.hotkeys = this.hotkeys || [];
        this.hotkeys.forEach(hotkey => {
            this.hotkeysService.add(hotkey);
        });
    }

    remoteHotkeys() {
        this.hotkeysService.remove(this.hotkeys);
    }
    //#endregion

    //#region CACHE_Storage

    remoteSessionStorage(tableEnum?: string) {
        //Ví dụ
        let keyString = 'combo-data-' + ComboBoxTableCode.GetAllTopic;

        if (tableEnum) {
            let arrkey: string[] = Object.keys(sessionStorage);
            arrkey.forEach((key) => {
                if (key.startsWith(tableEnum)) {
                    sessionStorage.removeItem(key);
                }
            })
        } else {
            sessionStorage.clear();
        }
    }
    //#endregion
}
