<!--     <div class="extension-header" style="float: left;padding: 10px">-->
<!--         <a [href]="urlCongKhaiGia"  target="_blank">Cổng kê khai giá</a>-->
<!--     </div>-->
<div nz-dropdown
     class="d-flex align-items-center"
     nzPlacement="bottomRight"
     [nzDropdownMenu]="userMenuTpl" style="color:#608180">
    <div class="show-name" style="margin-right: 8px;">
        <strong>{{ shownLoginName }}</strong>
    </div>
    <div class="border:1px solid black;border-radius:50%;">
        <nz-avatar [nzSrc]="profilePicture" nzSize="large"></nz-avatar>
    </div>
</div>
<nz-dropdown-menu #userMenuTpl="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item *ngIf="isImpersonatedLogin" (click)="backToMyAccount()">
            <i nz-icon nzType="api" nzTheme="outline"></i> {{'_QuayLaiTaiKhoanChinh' | localize}}
        </li>
        <li nz-menu-divider *ngIf="isImpersonatedLogin"></li>

        <ng-container *ngIf="tenant!=null && tenant.id>0">
            <li nz-menu-item (click)="changeAdmin()" *ngIf="showAdmin">
                <i nz-icon nzType="setting"></i>
                {{ 'Pages_TongQuan_Admin' | localize }}
            </li>
            <li nz-menu-item (click)="changeLecturers()" *ngIf="showLecturers">
                <i nz-icon nzType="team"></i>
                {{ 'Pages_TongQuan_Lecturers' | localize }}
            </li>
            <li nz-menu-item (click)="changeStudent()" *ngIf="showStudents">
                <i nz-icon nzType="user"></i>
                {{ 'Pages_TongQuan_Students' | localize }}
            </li>

            <li nz-menu-divider></li>
        </ng-container>
        <li nz-menu-item (click)="changeProfile()">
            <i nz-icon nzType="profile"></i>
            {{'_HoSoCaNhan' | localize}}
        </li>
        <li nz-menu-item (click)="changeProfilePicture()">
            <i nz-icon nzType="picture"></i>

            {{"ChangeProfilePicture" | localize}}
        </li>

        <li nz-menu-item (click)="clearSessionStore()">
            <i nz-icon nzType="delete" nzTheme="outline"></i>
            {{'_XoaBoNhoDem' | localize}}
        </li>
        <li nz-menu-item (click)="changePassword()">
            <i nz-icon nzType="sync"></i>
            {{ 'ChangePassword' | localize }}
        </li>

        <!--            <li nz-menu-item (click)="showLoginAttempts()">-->
        <!--                <i nz-icon nzType="bars"></i>-->
        <!--                {{"LoginAttempts" | localize}}-->
        <!--            </li>-->
      

        <!--            <li nz-menu-item (click)="changeMySettings()">-->
        <!--                <i nz-icon nzType="setting"></i>-->
        <!--                {{"MySettings" | localize}}-->
        <!--            </li>-->
        <li nz-menu-divider></li>
        <li nz-menu-item (click)="logout()">
            <i nz-icon nzType="logout"></i>

            {{ 'Logout' | localize }}
        </li>

    </ul>
</nz-dropdown-menu>
