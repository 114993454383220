<!-- BEGIN: Aside Menu -->
<!-- <div class="alain-default__aside" [ngStyle]="{'width': collapsed==true ? '64px' : '264px'}"> -->
<div class="alain-default__aside d-flex flex-column" (mouseenter)="hoverMenu()" (mouseleave)="overMenu()"
     [ngStyle]="{'width': isPinSidebar == true ? '264px' : ''}">

    <div class="flex-grow-1 ">
        <div class="d-flex justify-content-between" style="margin-bottom:8px">
            <a href="/" style="display:block" target="_self">
                <img class="logo-expanded" [src]="expandedLog" alt="{{settings.app.name}}" style="max-height:48px;"
                     [ngStyle]="{'display': isPinSidebar == true ? 'unset' : ''}" />
                <img class="logo-collapsed" [src]="collapsedLogo" alt="{{settings.app.name}}" style="max-height:48px;"
                     [ngStyle]="{'display': isPinSidebar == true ? 'none' : ''}" />
            </a>
            <div class="btn-toggle-footer" (click)="pinSidebar()"
                 [ngStyle]="{'display': isPinSidebar == true ? 'unset' : '','backgroundColor': isPinSidebar == true ? '#FCB815' : ''}">
                <div class="circle-check" [ngStyle]="{'backgroundColor': isPinSidebar == true ? '#fff' : '#fff'}">

                </div>
            </div>
        </div>

        <div class="alain-default__aside-inner">
            <ul nz-menu nzMode="inline" class="menu-full sidebar-here">
                <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.items,isChild:false }"></ng-container>
                <ng-template #menuTpl let-menus let-isChild="isChild">
                    <ng-container *ngFor="let item of menus;index as idx">
                        <ng-container *ngIf="showMenuItem(item)">
                            <li *ngIf="!(item.items && item.items.length > 0)" nz-menu-item nz-tooltip
                                nzTooltipPlacement="right" nzTooltipTitle="{{(item.name | localize)}}"
                                [nzSelected]="isMenuItemIsActive(item)">
                                <a [routerLink]="item.route" *ngIf="item.reuse">
                                    <ng-container *ngIf="!isChild;else childMenu">
                                        <div class="d-flex">

                                            <div style="width:18px;margin-right:12px;">
                                                <i style="font-size:18px;font-weight:600" class="{{'fas ' + item.icon}}"
                                                   *ngIf="item.icon"></i>
                                            </div>
                                            <div>
                                                <span class="fw-600">{{ item.name | localize }}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #childMenu>
                                        <div class="d-flex">
                                            <div class="col-2">
                                                <i style="font-size:16px;" class="{{'fas ' + item.icon}}"
                                                   *ngIf="item.icon"></i>
                                            </div>
                                            <div class="col-10">
                                                <span>{{ item.name | localize }}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </a>
                                <a [href]="item.route" *ngIf="!item.reuse">
                                    <div class="d-flex">
                                        <div class="col-2">
                                            <i style="font-size:16px;font-weight:600;" class="{{'fas ' + item.icon}}"
                                               *ngIf="item.icon"></i>
                                        </div>
                                        <div class="col-10">
                                            <span>{{ item.name | localize }}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li *ngIf="item.items && item.items.length > 0"
                                [ngClass]="isMenuRootItemIsActive(item)?'root-selected':''" nz-submenu
                                [nzOpen]="isMenuRootItemIsActive(item)" nzTitle="{{ item.name | localize }}"
                                [nzIcon]="item.icon">
                                <ul *ngIf="isPinSidebar;else mobileUl">
                                    <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: item.items, isChild: true }">
                                    </ng-container>
                                </ul>
                                <ng-template #mobileUl>
                                    <ul *ngIf="disableSubmenu">
                                        <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: item.items, isChild: true }">
                                        </ng-container>
                                    </ul>
                                </ng-template>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </ul>
        </div>
    </div>

</div>
<!-- END: Aside Menu -->
