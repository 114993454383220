import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignalRNotificationDto } from '@app/models/signal-model/signal-message-model';
import { NotificationStateService } from '@app/pages/he-thong/user-notification/service/notification-state.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    NotificationProcessDto,
    SysNotificationProcessPagingListRequest,
    SysNotificationtServiceProxy,
    Sys_NotificationKey,
    Sys_NotificationProcessStatus,
    Sys_NotificationType
} from '@shared/service-proxies/service-proxies';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'sys-notification',
    styleUrls: ['../../header.component.scss'],
    templateUrl: './sys-notification.component.html',
})

export class SysNotificationComponent extends AppComponentBase implements OnInit, AfterViewInit {
    isLoaded = false;
    countNumber: number = 0;
    isShowBoxNotification = false;
    listItem: NotificationProcessDto[] = [];
    Sys_NotificationProcessStatus = Sys_NotificationProcessStatus;
    Sys_NotificationType = Sys_NotificationType;
    Sys_NotificationKey = Sys_NotificationKey;
    profilePictureId: number;
    pictureUrlMinio: number;
    profilePictureUrl: string;
    AppConsts = AppConsts;
    constructor(
        injector: Injector,
        private _dataService: SysNotificationtServiceProxy,
        private router: Router,
        private _stateService: NotificationStateService,
        private notification: NzNotificationService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.callSignalR();
        this.loadDataNotification();
    }

    ngAfterViewInit(){
        this._stateService.isReloadNotification$
        .subscribe((res) => {
            if (res) {
                this.loadDataNotification();
            }
        });
    }

    callSignalR() {
        abp.event.on('app.getSystemNotification', (message: SignalRNotificationDto) => {
            if (message != null && message.userIds.includes(this.appSession.userId)) {
                this.loadDataNotification();
            }
        });
    }

    loadStatus(item: NotificationProcessDto) {
        if (item.metaData) {
            var convertObj = JSON.parse(item.metaData);
            if (item.notificationType == Sys_NotificationType.OLS_Course && item.notificationKey != Sys_NotificationKey.Notify_Course_Removal) {
                this.router.navigate([`app/my-training/my-course/detail/` + convertObj.ObjectId]);
            }
            else if (item.notificationType == Sys_NotificationType.OLS_Exam) {
                this.router.navigate([`student/exam-doing/` + convertObj.ObjectId]);
            }
            else if (item.notificationType == Sys_NotificationType.OLS_TrainingRoute) {

            }
            else {
                this.router.navigate([`student/survey-doing/` + convertObj.ObjectId]);
            }

            if (item.notificationType == Sys_NotificationType.OLS_Course && item.notificationKey == Sys_NotificationKey.Notify_Course_Removal) {
                this.openRealtimeNotification('warning', convertObj.subject)
            }
        }

        this._dataService
            .updateStatusNotiProcess(item.id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.loadDataNotification();
            });
    }

    openRealtimeNotification(type: string, content: string): void {
        this.notification.create(
            type,
            this.l('Notifications'),
            content
        );
    }

    loadDataNotification() {
        abp.ui.setBusy();
        this._dataService
            .notificationProcessGetListByUser()
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.listItem = response;
                this.countNumber = this.listItem.filter(x => x.status != Sys_NotificationProcessStatus.Read).length;
            });
    }

    showBoxNotification() {
        if (this.isShowBoxNotification == true) {
            this.isShowBoxNotification = false;
        } else {
            this.isShowBoxNotification = true;
        }
    }

    viewAllNotification() {
        this.router.navigate([`app/he-thong/view-all-notifications`]);
    }

    clickedOutside() {
        this.isShowBoxNotification = false;
    }
}
