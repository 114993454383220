import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'oraPaging'
})
export class oraPaging implements PipeTransform {

    constructor() {
    }

    public transform(value: any, page: number = 0, pageSize): any {
        if (value && page && pageSize) {
            let from = page>1?((page - 1) * pageSize):0;
            let to = page * pageSize;
            var arr = value.slice(from, to);
            return arr;
        }
        else {
            return value;
        }
    }
}
