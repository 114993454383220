import { Component, Input, OnInit } from '@angular/core';

@Component({
    templateUrl: './empty-custom.component.html'
})
export class EmptyCustomComponent implements OnInit {

    @Input() title: "EmptyCustomComponent";

    constructor() { }

    ngOnInit() {
    }
}
