import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatBytes'
})
export class formatBytesPipe implements PipeTransform {
    transform(bytes: number, decimalPlaces: number = 2): string {
        const gib = bytes / (1024 * 1024 * 1024);
        if (gib >= 1) {
            return gib.toFixed(decimalPlaces) + ' GiB';
        }
        else {
            const mib = bytes / (1024 * 1024);
            if(mib>=1){
                return mib.toFixed(decimalPlaces) + ' MiB';
            }
            else{
                const kib = bytes / 1024;
                return kib.toFixed(decimalPlaces) + ' KiB';
            }
        }
    }
}
