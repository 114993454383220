import { Pipe, PipeTransform } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';

@Pipe({
    name: 'cutString',
    pure: true
})
export class CutStringPipe implements PipeTransform {

    transform(value: any, maxLength: string): any {
        if (value) {
            if (value && value.length > maxLength) {
                value = value.slice(0, maxLength) + "...";
            }
            return value;
        }
        return value;
    }

}
