import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, Injector, OnInit } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { ProfileServiceProxy, ROLE_LEVEL, SessionServiceProxy, TenantLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { AbpSessionService } from 'abp-ng2-module';
import { ImpersonationService } from '@app/pages/admin/users/impersonation.service';
import { SessionStorageKey } from '@app/shared/common/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';

@Component({
    selector: 'header-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
})
export class HeaderUserComponent extends AppComponentBase implements OnInit {
    shownLoginNameTitle = '';
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();
    urlCongKhaiGia = AppConsts.webSiteRootAddress + '/cong-khai-gia';
    //permistion
    showAdmin: boolean = false;
    showLecturers: boolean = false;
    showStudents: boolean = false;
    isImpersonatedLogin = false;
    ROLE_LEVEL=ROLE_LEVEL;

    constructor(
        injector: Injector,
        private authService: AppAuthService,
        private route: Router,
        private modalSrv: NzModalService,
        private messageSrv: NzMessageService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _sessionServiceProxy: SessionServiceProxy,
        private modalService: NzModalService,
        private _abpSessionService: AbpSessionService,
        private _impersonationService: ImpersonationService,
        private _appSessionService:AppSessionService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.registerToEvents();

        this.showAdmin = abp.auth.isGranted("Pages.TongQuan.Admin");
        this.showLecturers = abp.auth.isGranted("Pages.TongQuan.Lecturers");
        this.showStudents = abp.auth.isGranted("Pages.TongQuan.Students");

        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
    }

    changeAdmin() {
        this.route.navigateByUrl('/app/main/dashboard/admin');
    }

    changeLecturers() {
        this.route.navigateByUrl('/app/main/dashboard/lecturers');
    }

    changeStudent() {
        this.route.navigateByUrl('/app/main/dashboard/students');
    }

    changeProfile(){
        this.route.navigate([`app/he-thong/user/profile`]);
    }

    backToMyAccount() {
        this._impersonationService.backToImpersonator();
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user?.userName;
        this.tenant = this.appSession.tenant;
    }

    clearSessionStore(): void {
        this.modalSrv.confirm({
            nzTitle: 'Make sure clear all local storage?',
            nzOnOk: () => {
                sessionStorage.clear();
                localStorage.clear();
                this.messageSrv.success('Clear Finished!');
                window.location.reload();
            },
        });
    }

    changePassword(): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = this.l('ChangePassword');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ChangePasswordModalComponent,
            nzFooter: null,
            nzWidth: '50%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result) => {
            if (result) {
                this.logout();
            }
        });
    }

    //#region changeProfilePicture

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });
    }

    getProfilePicture(): void {
        if (this.appSession.userId) {
            this._profileServiceProxy.getProfilePicture().subscribe((result) => {
                if (result && result.profilePicture) {
                    this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
                }
            });
        }
    }

    changeProfilePicture(): void {
        this.modalHelper
            .create(
                ChangeProfilePictureModalComponent,
                {

                },
                {
                    size: 768,
                    includeTabs: false,
                    modalOptions: {
                        nzTitle: this.l('ChangeProfilePicture'),
                        nzMaskClosable: false,
                    },
                },
            )
            .subscribe((result) => {
                if (result) {

                }
            });
    }
    //#endregion

    showLoginAttempts(): void {
        this.modalHelper.create(LoginAttemptsModalComponent).subscribe((result) => { });
    }

    changeMySettings(): void {
        this.modalHelper.createStatic(MySettingsModalComponent).subscribe((result) => { });
    }

    logout(): void {

        //CustomCache
        sessionStorage.clear();
        localStorage.clear();

        //AbpCache
        this._sessionServiceProxy.clearUserSession().subscribe();
        this.authService.logout();
    }
}
