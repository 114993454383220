<a nz-dropdown nzTrigger="click" class="position-relative" [nzDropdownMenu]="menu">
    <i class="fa-regular fa-comment"></i>
    <span *ngIf="totalUnread()>0" class="badge-chat-custom badge-up badge-pill bg-danger">{{totalUnread()}}</span>
</a>


<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="menu-chat-inbox">
        <li nz-menu-item>
            <h4 class="title-header-box-chat">{{'Sms' | localize}}</h4>
        </li>
        <ng-container *ngFor="let item of arrDataRoom;index as idx">
            <li nz-menu-item class=" chat-contact-item" *ngIf="idx<5" (click)="viewDetailChat(item)">
                <span class="b-avatar badge-minimal badge-light rounded-circle" style="width: 42px; height: 42px;">
                    <i class="fa-solid fa-user"></i>
                    <nz-badge nzStatus="success" class="badge-user-status"></nz-badge>
                </span>
                <div class="chat-info flex-grow-1">
                    <h5 class="mb-0"> {{getRoomName(item)}} </h5>
                    <p class="card-text text-truncate {{hasReadRoomMessage(item)?'isRead':'unread'}}">
                        {{getLastMessage(item)?.message | cutString: '20'}}</p>
                </div>
                <div class="chat-meta text-nowrap pt-25 d-flex flex-column align-items-end">
                    <small class="float-right chat-time"> {{getLastMessage(item)?.messageDate|timeAgo}}
                    </small>
                </div>
            </li>
        </ng-container>
        <li nz-menu-item>
            <!-- <btn-add-item typeBtn="primary" (click)="viewChatAll()"(clickEvent)="save()" icon="" [text]="'_XemTatCa' | localize">
            </btn-add-item> -->
            <button type="button" nz-button nzType="primary"
            style="text-align: center;
            margin: 5px auto;
            display: block;
            width: 100%;" (click)="viewChatAll()">{{'_XemTatCa' | localize}}</button>
            <!-- <a (click)="viewChatAll()">{{'_XemTatCa' | localize}}</a> -->
        </li>
    </ul>
</nz-dropdown-menu>
