import { Component, Injector, OnInit } from '@angular/core';
import { CommonCrudComponentBase, PagedRequestDto } from '@shared/common/common-crud-component-base';
import { UserAttendancedDto, UserAttendancedPagingListRequest, UserAttendancedServiceProxy } from '@shared/service-proxies/service-proxies';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import allLocales from '@fullcalendar/core/locales-all';

@Component({
    selector: 'user-attendance',
    styleUrls: ['../header.component.scss'],
    template: `
                <i class="fas fa-user-check" [ngClass]="{'attendance-active': isAttendanceToday, 'attendance-non-active': !isAttendanceToday}" (click)="showBoxAttendance(isShowAttendanceBox)"></i>
                <div (mouseleave)="hiddenBox()" [ngStyle]="{'display': isShowAttendanceBox == false ? 'none' : 'block'}"
                    class="attendance-box">
                    <div class="position-relative">
                        <div class="bg-decor"></div>
                        <button class="btn-attendance" (click)="handleAttendance()" [ngStyle]="{'visibility': isAttendanceToday == false ? 'visible' : 'hidden'}">{{'_DiemDanh' | localize}}</button>
                        <div class="calendar-frame" *ngIf="isLoaded">
                            <nz-date-picker [nzShowToday]="false" [nzShowNow]="false" [nzDateRender]="tplRender" nzInline [(ngModel)]="date" (ngModelChange)="onChange($event)"
                                ></nz-date-picker>
                            <ng-template #tplRender let-current>
                                <div class="ant-picker-cell-inner" [class.border]="activeAttendanced(current)">
                                    {{ current.getDate() }}
                                </div>
                            </ng-template>
                        </div>
                        <!-- <div class="calendar-frame w-100">
                            <full-calendar #calendar [options]="calendarOptions"></full-calendar>
                        </div> -->
                    </div>
                </div>
              
                 `
})
export class UserAttendanceComponent extends CommonCrudComponentBase<UserAttendancedDto, number, UserAttendancedServiceProxy, UserAttendancedDto> implements OnInit {

    isShowAttendanceBox: boolean = false;
    date = null;
    rangeDate = null;
    isAttendanceToday = false;
    objDate: UserAttendancedDto[] = [];
    arrDate = [];
    arrMonth = [];
    arrYear = [];
    isLoaded = false;
    arrTime = [];

    calendarOptions = {
        // editable: true,
        locales: allLocales,
        locale: abp.localization.currentLanguage.name,
        timeZone: "UTC",
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next',
            right: 'dayGridMonth'
        }
    };

    constructor(injector: Injector,
        private crudServiceProxy: UserAttendancedServiceProxy) {
        super(injector, crudServiceProxy);
    }

    protected fetchDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        let input = new UserAttendancedPagingListRequest();
        input.isFullRecord = true;
        input.isGetSingleUser = true;
        let req = Object.assign({
            ...input,
            ...this.searchDto
        });
        this.getListPaging$(req)
            .pipe(finalize(finishedCallback))
            .subscribe(result => {
                this.dataList = result.items;
                this.objDate = result.items as UserAttendancedDto[];

                //current DateTime
                let time = moment().toDate();
                time.setHours(0);
                time.setMinutes(0);
                time.setSeconds(0);
                time.setMilliseconds(0);

                //arr DateTime from server
                this.arrTime = this.objDate.map(m => {
                    let timeTemp = moment().toDate();
                    timeTemp.setDate(m.day);
                    timeTemp.setFullYear(m.year);
                    timeTemp.setMonth(m.month - 1);
                    timeTemp.setHours(0);
                    timeTemp.setMinutes(0);
                    timeTemp.setSeconds(0);
                    timeTemp.setMilliseconds(0);
                    return timeTemp.getTime();
                });

                //This is check user attendance
                if (this.arrTime.includes(time.getTime())) {
                    this.isAttendanceToday = true;
                }

                this.isLoaded = true;
            });
    }

    ngOnInit() {
        this.refresh();
    }

    activeAttendanced(current){
       return (this.arrTime.includes(current.getTime()));
    }
    
    onChange(result: Date): void {
        console.log(this.date, 'date');
    }

    showBoxAttendance(isShow: boolean) {
        if (isShow == true) {
            this.isShowAttendanceBox = false;
        } else {
            this.isShowAttendanceBox = true;
        }
    }

    hiddenBox() {
        this.isShowAttendanceBox = false;
    }

    handleAttendance() {
        let obj = new UserAttendancedDto();
        this.crudServiceProxy.createOrUpdate(obj)
            .pipe(finalize(() => {
                abp.ui.clearBusy();
            }))
            .subscribe(result => {
                if (result.isSuccessful) {
                    this.notify.success(this.l("_DaDiemDanhHomNay"));
                    this.refresh();
                } else {
                    this.notify.error(result.errorMessage);
                }
            });
    }
}

