import {Pipe, PipeTransform} from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';

@Pipe({
    name: 'timeAgo',
    pure: true
})
export class TimeAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            const intervals: { [key: string]: number } = {
                '_namTruoc': 31536000,
                '_ThangTruoc': 2592000,
                '_TuanTruoc': 604800,
                '_NgayTruoc': 86400,
                '_GioTruoc': 3600,
                '_PhutTruoc': 60,
                '_GiayTruoc': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + AppUtilityService.localize(i); // singular (1 day ago)
                    } else {
                        return counter + ' ' + AppUtilityService.localize(i+'s'); // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}
