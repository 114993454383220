import {
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
import { AppMenu } from '../nav/app-menu';
import { AppNavigationService } from '../nav/app-navigation.service';
import { AppMenuItem } from '../nav/app-menu-item';
import { SettingsService } from '@delon/theme';
import { AppChangeLayoutStateService } from '@app/shared/states/app-change-layout-state.service';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { TenantPublicDto } from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'layout-sidebar-custom',
    templateUrl: './side-bar-menu.component.html',
    styleUrls: ['./side-bar-menu.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class SideBarMenuComponent implements OnInit {
    menu: AppMenu = null;

    disableSubmenu = false;
    currentRouteUrl = '';
    expandedLog = './assets/images/logo-long.png';
    collapsedLogo = './assets/images/logo-short.png';
    currentPostion: number;
    isPinSidebar = false;
    @ViewChild('menu-full') ul: ElementRef;
    baseUrlMinio: string = AppConsts.getBaseUrlMinio();

    constructor(
        private router: Router,
        private _appNavigationService: AppNavigationService,
        public settings: SettingsService,
        private changeLayoutService: AppChangeLayoutStateService,
        private _elementRef: ElementRef,
        private _renderer: Renderer2
    ) {
    }

    get collapsed() {
        return this.settings.layout.collapsed;
    }

    toggleCollapsed() {
        this.settings.setLayout('collapsed', !this.collapsed);
    }

    ngOnInit() {

        this.menu = this._appNavigationService.getMenu();

        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(
                (event) =>
                    (this.currentRouteUrl = this.router.url.split(/[?#]/)[0])
        );
        
        const objTenant = sessionStorage.getItem("current-tenant");
        if (objTenant) {
            var tenant = JSON.parse(objTenant) as TenantPublicDto;
            if (tenant.logoSlidebar != null) {
                this.expandedLog = this.baseUrlMinio + tenant.logoSlidebar;
            }
        }

        this.pinSidebar();
    }

    toggleCollapsedSidebar() {
        const collapsed = !this.settings.layout.collapsed;
        this.settings.setLayout('collapsed', collapsed);
        abp.event.trigger('abp.theme-setting.collapsed', collapsed);
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        let flag = this._appNavigationService.showMenuItem(menuItem);
        return flag;
    }

    isMenuItemIsActive(item): boolean {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }
        if (!item.route) {
            return false;
        }
        let urlTree = this.router.parseUrl(
            this.currentRouteUrl.replace(/\/$/, '')
        );
        let urlString =
            '/' +
            urlTree.root.children.primary.segments
                .map((segment) => segment.path)
                .join('/');
        let exactMatch = urlString === item.route.replace(/\/$/, '');
        if (!exactMatch && item.routeTemplates) {
            for (let i = 0; i < item.routeTemplates.length; i++) {
                let result = new FormattedStringValueExtracter().Extract(
                    urlString,
                    item.routeTemplates[i]
                );
                if (result.IsMatch) {
                    return true;
                }
            }
        }
        return exactMatch;
    }

    isMenuRootItemIsActive(item): boolean {
        let result = false;
        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }
        return false;
    }

    hoverMenu() {
        this.disableSubmenu = true;

        // Renderer does not change the backgorund.
        let length = document.querySelectorAll('.menu-full .ant-menu-submenu-arrow').length;
        for (let i = 0; i < length; i++) {
            const box = this._elementRef.nativeElement.querySelectorAll('.menu-full .ant-menu-submenu-arrow')[i];
            this._renderer.setStyle(box, 'display', 'block');
        }
    }
    overMenu() {
        this.disableSubmenu = false;

        if (this.isPinSidebar == false) {
            // Renderer does not change the backgorund.
            let length = document.querySelectorAll('.menu-full .ant-menu-submenu-arrow').length;
            for (let i = 0; i < length; i++) {
                const box = this._elementRef.nativeElement.querySelectorAll('.menu-full .ant-menu-submenu-arrow')[i];
                this._renderer.setStyle(box, 'display', 'none');
            }
        } else {
            let length = document.querySelectorAll('.menu-full .ant-menu-submenu-arrow').length;
            for (let i = 0; i < length; i++) {
                const box = this._elementRef.nativeElement.querySelectorAll('.menu-full .ant-menu-submenu-arrow')[i];
                this._renderer.setStyle(box, 'display', 'block');
            }
        }
    }

    pinSidebar() {

        if (this.isPinSidebar == false)
            this.isPinSidebar = true;
        else
            this.isPinSidebar = false;

        this.changeLayoutService.setLayoutSubjectPatchValue(this.isPinSidebar);
    }
}
