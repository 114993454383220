import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'oraArrayOrder'
})
export class OraArrayOrder implements PipeTransform {

    constructor() {
    }

    public transform(value: any, valueOrder: any): any {
        if (value && valueOrder) {
            value.sort(function (a, b) {
                return b[valueOrder] - a[valueOrder];
            });
            return value;
        }
        else {
            return value;
        }
    }
}
