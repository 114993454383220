import { Component, EventEmitter, HostListener, Injector, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ImpersonationService } from '@app/pages/admin/users/impersonation.service';
import { SettingsService } from '@delon/theme';
import { AbpSessionService } from '@node_modules/abp-ng2-module';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
import { AppConsts } from '../../../../shared/AppConsts';
import { AppMenu } from '../nav/app-menu';
import { AppMenuItem } from '../nav/app-menu-item';
import { AppNavigationService } from '../nav/app-navigation.service';
import { filter } from 'rxjs/operators';
import { AppChangeLayoutStateService } from '@app/shared/states/app-change-layout-state.service';
import { TenantPublicDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends AppComponentBase implements OnInit {
    menu: AppMenu = null;

    disableSubmenu = false;
    currentRouteUrl = '';
    collapsedLogo = './assets/images/logo-short.png';
    expandedLog = './assets/images/logo-long.png';
    logoMobile = './assets/images/logo-mobile.png';
    isToggleMenu = false;
    searchToggleStatus: boolean;
    isImpersonatedLogin = false;
    webSiteRootAddress: string;
    currentPosition: number;
    isShowMenuMobile: boolean = false;
    tenantId: number = null;
    tenantName: string = null;
    isPinSidebar = true;


    get collapsed() {
        return this.settings.layout.collapsed;
    }

    constructor(
        injector: Injector,
        public settings: SettingsService,
        private router: Router,
        private _appNavigationService: AppNavigationService,
        private _abpSessionService: AbpSessionService,
        private _impersonationService: ImpersonationService,
        private _changeLayoutService: AppChangeLayoutStateService,
        
    ) { super(injector); }


    ngOnInit(): void {
        // if(this.appSession?.tenant && this.appSession?.tenant.logoUrl){
        //     this.expandedLog = AppConsts.remoteServiceBaseUrl + `/api/FileUpload/GoToViewTempFile?url=` + this.appSession?.tenant.logoUrl;
        // }

   
        this.tenantId = this.appSession?.tenantId;
        if (this.appSession?.tenantId > 0) {
            this.tenantName = this.appSession?.tenant?.name;           
        }        
      

        //load menu-module
        this.menu = this._appNavigationService.getMenu();

        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(
                (event) =>
                    (this.currentRouteUrl = this.router.url.split(/[?#]/)[0])
            );

        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";

        this._changeLayoutService.isPinSidebar$.subscribe(result=>{
            this.isPinSidebar = result;
        })
    }

    toggleCollapsedSidebar() {
        const collapsed = !this.settings.layout.collapsed;
        this.settings.setLayout('collapsed', collapsed);
        abp.event.trigger('abp.theme-setting.collapsed', collapsed);
    }

    searchToggleChange() {
        this.searchToggleStatus = !this.searchToggleStatus;
    }

    showMenuAbsolute() {
        if (this.isShowMenuMobile == false)
            this.isShowMenuMobile = true
        else
            this.isShowMenuMobile = false
    }

    hideMenuAbsolute() {
        this.isShowMenuMobile = false
    }

    backToMyAccount() {
        this._impersonationService.backToImpersonator();
    }


    showMenuItem(menuItem: AppMenuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    isMenuItemIsActive(item): boolean {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.route) {
            return false;
        }

        let urlTree = this.router.parseUrl(
            this.currentRouteUrl.replace(/\/$/, '')
        );
        let urlString =
            '/' +
            urlTree.root.children.primary.segments
                .map((segment) => segment.path)
                .join('/');
        let exactMatch = urlString === item.route.replace(/\/$/, '');
        if (!exactMatch && item.routeTemplates) {
            for (let i = 0; i < item.routeTemplates.length; i++) {
                let result = new FormattedStringValueExtracter().Extract(
                    urlString,
                    item.routeTemplates[i]
                );
                if (result.IsMatch) {
                    return true;
                }
            }
        }
        return exactMatch;
    }

    isMenuRootItemIsActive(item): boolean {
        let result = false;
        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }
        return false;
    }

    redirectCertification(){
        this.router.navigate(['app/he-thong/user/profile/develope/certification'])
    }

    redirectAchievement(){
        this.router.navigate(['app/he-thong/user/profile/develope/achievement'])
    }

    redirectProcess(){
        this.router.navigate(['app/he-thong/user/profile/develope/process'])
    }

    @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
        this.currentPosition = window.scrollY;
    }

}
