import { Environment } from '@node_modules/@abp/ng.core';


export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';
    static tenancyName: string; //custom

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static fileManagementServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static abpEnvironment: Environment;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'PMS',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };

    static readonly grid = {
        defaultPageSize: 10,
        defaultPageSizes: [5, 10, 15, 20, 25, 30, 50, 80, 100],
    };

    static webSiteRootAddress: string;
    static fireBase = {
        apiKey: "AIzaSyCGasHFAdOqt0fZV4Zy6rYEdoA7h4fJwlI",
        authDomain: "lms-app-371be.firebaseapp.com",
        projectId: "lms-app-371be",
        storageBucket: "lms-app-371be.appspot.com",
        messagingSenderId: "168905651304",
        appId: "1:168905651304:web:f1d9c5ba80a3cd515b37dd",
        measurementId: "G-4DQX4YRFH7"

        //qa or product
        // apiKey: "AIzaSyBUGpJBpgDUj6p9OP9_wNYaJ_68qDa6SHQ",
        // authDomain: "lms-app-372407.firebaseapp.com",
        // databaseURL: "https://lms-app-372407-default-rtdb.firebaseio.com",
        // projectId: "lms-app-372407",
        // storageBucket: "lms-app-372407.appspot.com",
        // messagingSenderId: "914176507633",
        // appId: "1:914176507633:web:fb47453ec14545b789f350",
        // measurementId: "G-2845S87X5B"
    };

    static baseUrlMinio: string = "https://s3.minio.orenda.vn/lms-shared-file/";

    static getBaseUrlMinio() {
        return this.baseUrlMinio + localStorage.getItem('cacheTenancyName');
    }

    static getBaseUrlMinioNonTenancy() {
        return this.baseUrlMinio;
    }
}
