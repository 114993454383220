import { MyApplicationModule } from './pages/my-application/my-application.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        data: {
            breadcrumb: 'Trang chủ',
        },
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: '', redirectTo: '/app/main/home', pathMatch: 'full' },
                ],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then((m) => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc',
                loadChildren: () => import('./pages/danh-muc/danh-muc.module').then((m) => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'he-thong',
                loadChildren: () => import('./pages/he-thong/he-thong.module').then((m) => m.HeThongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'portal',
                loadChildren: () => import('./host/portal/portal.module').then((m) => m.PortalModule), // Lazy load portal module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thue-bao',
                loadChildren: () => import('./host/thue-bao/thue-bao.module').then((m) => m.ThueBaoModule), // Lazy load portal module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc-chung',
                loadChildren: () => import('./host/danh-muc-chung/danh-muc-chung.module').then((m) => m.DanhMucChungModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'content-library',
                loadChildren: () => import('./pages/content-library/content-library.module').then((m) => m.ContentLibraryRoutingModuleModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'training-management',
                loadChildren: () => import('./pages/training-management/training.module').then((m) => m.TrainingModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'my-training',
                loadChildren: () => import('./pages/my-training/my-training.module').then((m) => m.MyTrainingModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'teacher',
                loadChildren: () => import('./pages/teacher/teacher.module').then((m) => m.TeacherModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'report',
                loadChildren: () => import('./pages/report/report.module').then((m) => m.ReportModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'chat-management',
                loadChildren: () => import('./pages/chat-management/chat-management.module').then((m) => m.ChatManagementModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'my-application',
                loadChildren: () => import('./pages/my-application/my-application.module').then((m) => m.MyApplicationModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            { path: '**', redirectTo: '/app/main/home' },
        ],
    },
    {
        path: 'student',
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        loadChildren: () => import('./pages/student/student.module').then((m) => m.StudentModule), // Lazy load admin module
        data: { preload: true },
        canLoad: [AppRouteGuard],
    }
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router) { }
}
