import { Pipe, PipeTransform } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';

@Pipe({
    name: 'secondsToTime',
    pure: true
})
export class SecondsToTimePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            let minute = Math.floor(value / 60) + ' ' + AppUtilityService.localize('_Phut');
            let second = Math.floor(value % 60) + ' ' + AppUtilityService.localize('_Giay');
            return minute + ' ' + second;
        }
        return value;
    }
}
