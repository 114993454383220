<div class="alain-default__nav-wrap menu-top align-items-center"
    [ngStyle]="{'backgroundColor': currentPosition > 20 ? '#fff' : 'transparent','boxShadow': currentPosition>20 ? '0 4px 24px 0 #22292f1a' : 'none'}">
    <div class="nav-mobile">
        <div class="d-flex align-items-center">
            <label for="nav-mobile-input" class="nav__bars-btn" id="btn-bars">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5"></rect>
                        <path
                            d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                            fill="#000000" opacity="0.3"></path>
                    </g>
                </svg>
            </label>
         
        </div>
        <input type="checkbox" class="nav__input" id="nav-mobile-input">
        <label for="nav-mobile-input" class="nav__overlay">
        </label>
        <div class="nav__mobile">
            <div class="nav_mobile_header d-flex justify-content-between">
                <a style="display:block;" href="/">
                    <img [src]="expandedLog">
                </a>
                <label for="nav-mobile-input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x"
                        viewBox="0 0 16 16">
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </label>
            </div>
            <div class="alain-default__aside-inner mt-3">
                <ul nz-menu nzMode="inline">
                    <ng-container
                        *ngTemplateOutlet="menuTpl; context: { $implicit: menu.items,isChild:false }"></ng-container>
                    <ng-template #menuTpl let-menus let-isChild="isChild">
                        <ng-container *ngFor="let item of menus;index as idx">
                            <ng-container *ngIf="showMenuItem(item)">
                                <li *ngIf="!(item.items && item.items.length > 0)" nz-menu-item nz-tooltip
                                    nzTooltipPlacement="right" nzTooltipTitle="{{(item.name | localize)}}"
                                    [nzSelected]="isMenuItemIsActive(item)">
                                    <a [routerLink]="item.route" *ngIf="item.reuse">
                                        <ng-container *ngIf="!isChild;else childMenu">
                                            <div class="d-flex">

                                                <div style="width:18px;margin-right:12px;">
                                                    <i style="font-size:18px;font-weight:600"
                                                        class="{{'fas ' + item.icon}}" *ngIf="item.icon"></i>
                                                </div>
                                                <div>
                                                    <span class="fw-600">{{ item.name | localize }}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #childMenu>
                                            <div class="d-flex">
                                                <div class="col-2">
                                                    <i style="font-size:16px;" class="{{'fas ' + item.icon}}"
                                                        *ngIf="item.icon"></i>
                                                </div>
                                                <div class="col-10">
                                                    <span>{{ item.name | localize }}</span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </a>
                                    <a [href]="item.route" *ngIf="!item.reuse">
                                        <div class="d-flex">
                                            <div class="col-2">
                                                <i style="font-size:16px;font-weight:600;"
                                                    class="{{'fas ' + item.icon}}" *ngIf="item.icon"></i>
                                            </div>
                                            <div class="col-10">
                                                <span>{{ item.name | localize }}</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li *ngIf="item.items && item.items.length > 0"
                                    [ngClass]="isMenuRootItemIsActive(item)?'root-selected':''" nz-submenu
                                    [nzOpen]="isMenuRootItemIsActive(item)" nzTitle="{{ item.name | localize }}"
                                    [nzIcon]="item.icon">
                                    <ul *ngIf="isPinSidebar;else mobileUl">
                                        <ng-container
                                            *ngTemplateOutlet="menuTpl; context: { $implicit: item.items, isChild: true }">
                                        </ng-container>
                                    </ul>
                                    <ng-template #mobileUl>
                                        <ul *ngIf="disableSubmenu">
                                            <ng-container
                                                *ngTemplateOutlet="menuTpl; context: { $implicit: item.items, isChild: true }">
                                            </ng-container>
                                        </ul>
                                    </ng-template>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>

    <h1 class="tency-name" [ngStyle]="{'marginLeft': isPinSidebar == false ? '82px' : '276px'}">
        {{tenantName}}
    </h1>

    <ul class="alain-default__nav">
        <ng-container *ngIf="tenantId>0">
            <!-- <li nzTooltipTitle="Lịch sự kiện" nzTooltipPlacement="bottom" nz-tooltip>
                <a href="#">
                    <i class="fas fa-calendar"></i>
                </a>
            </li>
            <li nzTooltipTitle="{{'_DanhHieu' | localize}}" nzTooltipPlacement="bottom" nz-tooltip>
                <a (click)="redirectAchievement()">
                    <i class="fas fa-medal"></i>
                </a>
            </li>
            <li nzTooltipTitle="Chứng nhận" nzTooltipPlacement="bottom" nz-tooltip>
                <a (click)="redirectCertification()">
                    <i class="fas fa-certificate"></i>
                </a>
            </li> -->
            <li class="attendance">
                <user-attendance></user-attendance>
            </li>
            <!-- <li nzTooltipTitle="Tiến trình học tập" nzTooltipPlacement="bottom" nz-tooltip>
                <a (click)="redirectProcess()">
                    <i class="fas fa-chart-simple"></i>
                </a>
            </li> -->
            <!--<li class="item-chat-message">
                <sys-chat-message-inbox></sys-chat-message-inbox>

            </li>-->
            <li class="sys_notification">
                <sys-notification></sys-notification>
            </li>
        </ng-container>
        <li>
            <language-switch></language-switch>
        </li>
        <!-- <li *ngIf="isImpersonatedLogin">
            <button (click)="backToMyAccount()" nz-button nzType="link" title="{{'_QuayLaiTaiKhoanChinh' | localize}}">
                <i nz-icon nzType="api" nzTheme="outline"></i>
            </button>
        </li> -->
        <li>
            <header-user style="float: left;"></header-user>
        </li>
    </ul>

    <div class="menu-mobile-right">
        <div class="d-flex justify-content-end align-items-center">
            <div class="ml-3 attendance">
                <user-attendance></user-attendance>
            </div>
            <div class="ml-3 sys_notification">
                <sys-notification></sys-notification>
            </div>
            <language-switch class="ml-3"></language-switch>
            <header-user class="ml-3"></header-user>
        </div>
    </div>
</div>
