import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, Injector, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, RouteConfigLoadStart, Router } from '@angular/router';
import { LoadingService } from '@delon/abc/loading';
import { ReuseItem } from '@delon/abc/reuse-tab';
import { ScrollService, SettingsService } from '@delon/theme';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Subject, Subscription } from 'rxjs';
import { AppChangeLayoutStateService } from '@app/shared/states/app-change-layout-state.service';
import { NotificationFirebaseService } from './shared/notification-firebase.service';
import { ChatSignalrService } from './shared/services/chat-signalr.service';
import { SignalRHelper } from '@shared/helpers/SignalRHelper';

@Component({
    selector: 'app-app-main',
    templateUrl: './app.component.html',
})

export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {
    private notify$: Subscription;
    isFetching = false;
    $isFetching = new Subject<boolean>();
    $destroy = new Subject<boolean>();
    @ViewChild('settingHost', { read: ViewContainerRef, static: true })
    settingHost: ViewContainerRef;
    isToggle = true;
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && this.appSession.userId != null;
    //PageCurrent?
    installationMode = false;
    isPageCourseStudy = false;
    isPinSidebar = false;

    constructor(
        injector: Injector,
        private settings: SettingsService,
        private router: Router,
        scroll: ScrollService,
        private el: ElementRef,
        private renderer: Renderer2,
        public _zone: NgZone,
        private resolver: ComponentFactoryResolver,
        private loadingSrv: LoadingService,
        private changeLayoutService:AppChangeLayoutStateService,
        private notificationFirebaseService: NotificationFirebaseService,
        private _chatSignalrService: ChatSignalrService,
    ) {
        super(injector);

        // scroll to top in change page
        router.events.subscribe(evt => {
            if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
                this.loadingSrv.open({ type: 'spin', text: 'Vui lòng đợi trong giây lát ...' });
            }
            if (evt instanceof NavigationError || evt instanceof NavigationCancel) {
                this.loadingSrv.close();
                return;
            }
            if (evt instanceof NavigationEnd) {
                this.titleSrv.suffix = 'OLS-Online Learning Solution';
                //this.titleSrv.setTitle(); //set tilte khi chuyển tab
            }
            scroll.scrollToTop();
            this.loadingSrv.close();

        });
    }

    private setClass() {
        // const { el, doc, renderer, settings } = this;
        // const layout = settings.layout;
        // updateHostClass(
        //     el.nativeElement,
        //     renderer,
        //     {
        //         ['alain-default']: true,
        //         [`alain-default__fixed`]: layout.fixed,
        //         [`alain-default__collapsed`]: layout.collapsed,
        //     },
        //     true,
        // );

        // doc.body.classList[layout.colorWeak ? 'add' : 'remove']('color-weak');
    }

    ngOnInit(): void {


       this.changeLayoutService.isPinSidebar$
        .subscribe((res) => {
           this.isPinSidebar = res;
        });

        this.registerEvents();

        this.installationMode = UrlHelper.isInstallUrl(location.href);

        if (this.appSession.application) {
            SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        }

        this.notify$ = this.settings.notify.subscribe(() => this.setClass());
        this.setClass();

        this.activatedRoute.url.subscribe(() => {
            this.isPageCourseStudy = UrlHelper.isPageCourseStudy(location.href);
        });
        this.notificationFirebaseService.init();
    }

    registerEvents() {

        abp.event.on('abp.ui.setBusy', (message = this.l('_VuiLongChoDoiTrongGiayLat')) => {
            this.loadingSrv.open({ type: 'spin', text: message });
        });
        abp.event.on('abp.ui.clearBusy', () => {
            this.loadingSrv.close();
        });
    }

    tabChange($event: ReuseItem) {
        console.log('$event', $event);
    }

    tabClose($event: ReuseItem) {
        console.log('$event tabClose', $event);
    }

    ngAfterViewInit(): void {
        // Setting componet for only developer
        // if (!environment.production) {
        //     setTimeout(() => {
        //         const settingFactory = this.resolver.resolveComponentFactory(
        //             SettingDrawerComponent,
        //         );
        //         this.settingHost.createComponent(settingFactory);
        //     }, 22);
        // }
    }

    ngOnDestroy() {
        this.notify$.unsubscribe();
    }
}
