import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,

        //Danh mục
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,
        ApiServiceProxies.DonViHanhChinhServiceProxy,
        ApiServiceProxies.PositionServiceProxy,
        ApiServiceProxies.EmployeeServiceProxy,
        ApiServiceProxies.DepartmentServiceProxy,
        ApiServiceProxies.DepartmentTypeServiceProxy,

        //Hệ thống
        ApiServiceProxies.ComboServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,
        ApiServiceProxies.TenantRegisterServiceProxy,
        ApiServiceProxies.TaiKhoanManagementServiceProxy,
        ApiServiceProxies.GroupManagementServiceProxy,
        ApiServiceProxies.UserGroupServiceProxy,
        ApiServiceProxies.RatingScaleServiceProxy,
        ApiServiceProxies.CertificationServiceProxy,
        ApiServiceProxies.ExperiencePointServiceProxy,
        ApiServiceProxies.UserAttendancedServiceProxy,
        ApiServiceProxies.UserRankingServiceProxy,
        ApiServiceProxies.SysNotificationtServiceProxy,
        ApiServiceProxies.GeneralInformationServiceProxy,
        ApiServiceProxies.GeneralInformationServiceProxy,
        ApiServiceProxies.CourseCertificationServiceProxy,
        ApiServiceProxies.CourseCertificationDetailServiceProxy,
        ApiServiceProxies.ExamCertificationServiceProxy,
        ApiServiceProxies.ExamCertificationDetailServiceProxy,
        ApiServiceProxies.UserRegisterServiceProxy,

        //LandingPage
        ApiServiceProxies.LandingPagePublicServiceProxy,

        //Proficienxcy
        ApiServiceProxies.AbilityServiceProxy,
        ApiServiceProxies.AbilityGroupServiceProxy,
        ApiServiceProxies.AbilityLevelServiceProxy,
        ApiServiceProxies.AbilitySettingServiceProxy,
        ApiServiceProxies.AbilitySettingCourseServiceProxy,

        //ContentRepository
        ApiServiceProxies.ContentRepositoryServiceProxy,

        //Course
        ApiServiceProxies.CourseServiceProxy,
        ApiServiceProxies.CourseUserServiceProxy,
        ApiServiceProxies.CourseGroupUserServiceProxy,
        ApiServiceProxies.CourseDepartmentServiceProxy,
        ApiServiceProxies.LessonServiceProxy,
        ApiServiceProxies.ReferenceContentServiceProxy,
        ApiServiceProxies.CourseConditionAbilityServiceProxy,
        ApiServiceProxies.CourseConditionCourseServiceProxy,
        ApiServiceProxies.CourseRegisterServiceProxy,
        //Attendence
        ApiServiceProxies.LessonAttendanceServiceProxy,
        //My Course Action
        ApiServiceProxies.CourseCommentServiceProxy,
        ApiServiceProxies.CourseNoteServiceProxy,
        ApiServiceProxies.CourseQuestionFeedbackServiceProxy,

        //Survey
        ApiServiceProxies.SurveyServiceProxy,
        ApiServiceProxies.SurveyTopicServiceProxy,
        ApiServiceProxies.SurveyDepartmentServiceProxy,
        ApiServiceProxies.SurveyGroupUserServiceProxy,
        ApiServiceProxies.SurveyUserServiceProxy,

        //MySurvey
        ApiServiceProxies.MySurveyServiceProxy,

        //portal
        ApiServiceProxies.CategoryServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.MenuServiceProxy,
        ApiServiceProxies.MenuCategoryServiceProxy,
        ApiServiceProxies.SystemConfigAppServiveServiceProxy,
        ApiServiceProxies.BlogServiceProxy,
        ApiServiceProxies.AdvPositionServiceProxy,
        ApiServiceProxies.AdvServiceProxy,
        ApiServiceProxies.GalleryServiceProxy,
        ApiServiceProxies.VideoServiceProxy,

        //ContenManagement
        ApiServiceProxies.QuestionBankServiceProxy,
        ApiServiceProxies.SurveyQuestionServiceProxy,
        ApiServiceProxies.ExamBankServiceProxy,

        //Training Management
        ApiServiceProxies.TopicManagementServiceProxy,
        ApiServiceProxies.ExamServiceProxy,
        ApiServiceProxies.ExamTopicServiceProxy,
        ApiServiceProxies.ExamDepartmentServiceProxy,
        ApiServiceProxies.ExamUserServiceProxy,
        ApiServiceProxies.ExamGroupUserServiceProxy,
        ApiServiceProxies.ExamLecturesServiceProxy,
        ApiServiceProxies.TrainingCostServiceProxy,
        ApiServiceProxies.TrainingCostTypeServiceProxy,
        ApiServiceProxies.QuizServiceProxy,
        ApiServiceProxies.ExamTopicRegisterServiceProxy,
        ApiServiceProxies.ExamShiftServiceProxy,
        ApiServiceProxies.ExamShiftLecturerServiceProxy,
        ApiServiceProxies.ExamBankShiftServiceProxy,

        //Student Study
        ApiServiceProxies.MyCourseServiceProxy,
        ApiServiceProxies.MyExamServiceProxy,

        //RoadMap
        ApiServiceProxies.RoadmapServiceProxy,
        ApiServiceProxies.RoadmapUserServiceProxy,
        ApiServiceProxies.RoadmapGroupUserServiceProxy,
        ApiServiceProxies.RoadmapDepartmentServiceProxy,

        //MyRoadmap
        ApiServiceProxies.MyRoadmapServiceProxy,

        //My lesson
        ApiServiceProxies.MyLessonDoingServiceProxy,

        //Teacher
        ApiServiceProxies.TeacherGradingExamServiceProxy,
        ApiServiceProxies.TeacherGradingCourseServiceProxy,
        ApiServiceProxies.ExamSupervisionServiceProxy,
        ApiServiceProxies.CourseFeedbackServiceProxy,

        //Report
        ApiServiceProxies.SurveyReportServiceProxy,
        ApiServiceProxies.CourseReportServiceProxy,
        ApiServiceProxies.ExamReportServiceProxy,
        ApiServiceProxies.TestReportServiceProxy,

        //Minio Serive
        ApiServiceProxies.FileMinioManagementServiceProxy,

       //Dashboard
        ApiServiceProxies.AdminDashboardServiceProxy,
        ApiServiceProxies.TeacherDashboardServiceProxy,
        ApiServiceProxies.StudentDashboardServiceProxy,

        //user profile
        ApiServiceProxies.UserProfileServiceProxy,

        //Chat management
        ApiServiceProxies.ChatRoomServiceProxy,
        ApiServiceProxies.ChatMessageServiceProxy,

        //Event
        ApiServiceProxies.AppEventServiceProxy,
        ApiServiceProxies.EventDepartmentServiceProxy,
        ApiServiceProxies.EventGroupServiceProxy,
        ApiServiceProxies.EventUserServiceProxy,




        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule { }
