import { Directive, Injector, Input, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DeleteMultiDtoOfInt64, IFilterColumns } from '@shared/service-proxies/service-proxies';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { finalize } from 'rxjs/operators';
import * as $ from 'jquery';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';

export class PagedResultDto {
    items: any[];
    totalCount: number;
    listOfSelectedValue?: number[];
}

export class EntityDto {
    id: number;
}

export class PagedRequestDto {
    skipCount: number;
    maxResultCount: number;
    sorting: string;
}

@Directive()
export abstract class CommonCrudComponentBase<EntityDto, TKey, TServiceProxy, PagedOutput> extends AppComponentBase implements OnInit {
    public pageSize = AppConsts.grid.defaultPageSize;
    public pageNumber = 1;
    public totalPages = 1;
    public skipCount: number; //
    public totalItems: number;
    public isTableLoading = true;
    public isLoadBusy = false;
    public allChecked = false;
    public allCheckboxDisabled = false;
    public checkboxIndeterminate = false;
    public selectedDataItems: PagedOutput[] = [];
    public sorting: string = undefined;
    searchDto: any = {};
    filterColumns: IFilterColumns[];
    dataList: PagedOutput[] = [];
    public booleanFilterList: any[] = [
        { text: this.l('All'), value: 'All' },
        { text: this.l('Yes'), value: true },
        { text: this.l('No'), value: false },
    ];
    isView = false;
    modal: NzModalRef;
    @Input() listOfSelectedValue: any[] = [];
    @Input() isModal = false;
    @Input() isGetFullObj = false;

    constructor(private injector: Injector, private _crudServiceProxy: TServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        this.initBeforeFetch();
        if (this.isModal == true) {
            this.modal = this.injector.get(NzModalRef);
        }
        this.refresh();
    }

    initBeforeFetch(): void {

    }

    refresh(): void {
        this.pageNumber = 1;
        this.restCheckStatus(this.dataList);
        this.getDataPage(this.pageNumber);
    }

    clear() {
        this.searchDto = {};
        this.initBeforeFetch();
        this.refresh();
    }

    public getDataPage(page: number): void {
        const req = new PagedRequestDto();
        req.maxResultCount = this.pageSize;
        this.skipCount = (page - 1) * this.pageSize;
        req.skipCount = this.skipCount;
        req.sorting = this.sorting;
        this.isTableLoading = true;
        if (this.isLoadBusy) {
            abp.ui.setBusy();
        }
        this.fetchDataList(req, page, () => {
            this.isTableLoading = false;
            if (this.isLoadBusy) {
                abp.ui.clearBusy();
            }
            // cập nhật trạng thái disable
            this.refreshAllCheckBoxDisabled();
        });
    }

    refreshAllCheckBoxDisabled(): void {
        this.allCheckboxDisabled = this.dataList.length <= 0;
    }

    public pageNumberChange(): void {
        if (this.pageNumber > 0) {
            this.restCheckStatus(this.dataList);
            this.getDataPage(this.pageNumber);
        }
    }

    checkAll(value: boolean): void {
        this.dataList.forEach(data => ((<any>data).checked = this.allChecked));
        this.refreshCheckStatus(this.dataList);
    }

    refreshCheckStatus(entityList: any[]): void {
        // Chọn tất cả
        const allChecked = entityList.every(value => value.checked === true);
        // bỏ chọn tất cả
        const allUnChecked = entityList.every(value => !value.checked);
        this.allChecked = allChecked;
        // Kiểu hộp chọn
        this.checkboxIndeterminate = !allChecked && !allUnChecked;
        // Dữ liệu đã chọn
        this.selectedDataItems = entityList.filter(value => value.checked);
    }

    restCheckStatus(entityList: any[]): void {
        this.allChecked = false;
        this.checkboxIndeterminate = false;
        // Dữ liệu đã chọn
        this.selectedDataItems = [];
        entityList.forEach(value => (value.checked = false));
    }

    public showPaging(result: PagedResultDto): void {
        this.totalItems = result.totalCount;
    }

    gridSort(sort: { key: string; value: string }) {
        this.sorting = undefined;
        let ascOrDesc = sort.value; // 'ascend' or 'descend' or null
        const filedName = sort.key;
        if (ascOrDesc) {
            ascOrDesc = abp.utils.replaceAll(ascOrDesc, 'end', '');
            const args = ['{0} {1}', filedName, ascOrDesc];
            const sortingStr = abp.utils.formatString.apply(this, args);
            this.sorting = sortingStr;
        }
        this.refresh();
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }
        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }
        return false;
    }

    setWidthColumnSearch(): number {
        if (window.innerWidth < 992) {
            return 24;
        }
        else if (window.innerWidth < 1200) {
            return 24;
        }
        else {
            return 18;
        }
    }

    getListPaging$(body: any | undefined): Observable<any> {
        return (this._crudServiceProxy as NzSafeAny).getPagingList(body);
    }

    protected fetchDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        let input = Object.assign({
            ...this.searchDto
        });
        input.ignoreListId = this.listOfSelectedValue ? this.listOfSelectedValue.filter(m => m != null) : null;
        input.maxResultCount = request.maxResultCount;
        input.skipCount = request.skipCount;
        input.sorting = request.sorting;
       
        this.getListPaging$(input)
            .pipe(finalize(finishedCallback))
            .subscribe(result => {

                this.dataList = result.items;
                if (result.listOfSelectedValue) {
                    this.listOfSelectedValue = result.listOfSelectedValue;
                }
                this.showPaging(result);
                this.afterGetDataPaging();
            });
    }

    afterGetDataPaging() {

    }

    //#region Action More
    showCreateOrEdit(dataItem: EntityDto) {
    }

    delete(dataItem: EntityDto): void {
        this.message.confirm(
            '', this.l('_BanCoChacMuonXoa'),
            (isConfirmed) => {
                if (isConfirmed) {
                    abp.ui.setBusy();
                    (this._crudServiceProxy as NzSafeAny).deleteById(dataItem['id'])
                        .pipe(finalize(() => abp.ui.clearBusy()))
                        .subscribe(() => {
                            this.refresh();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                            this.afterDelete();
                            //this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            },
        );
    }

    deleteMulti(): void {
        this.message.confirm(
            '', this.l('_BanChacChanMuonXoaBanGhiDaChon'),
            (isConfirmed) => {
                if (isConfirmed) {
                    
                    abp.ui.setBusy();
                    const ids = this.selectedDataItems.map(m => m['id']);
                    let body = Object.assign(new DeleteMultiDtoOfInt64(), {
                        listId: ids,
                    });
                    (this._crudServiceProxy as NzSafeAny).deleteMulti(body)
                        .pipe(finalize(() => abp.ui.clearBusy()))
                        .subscribe(() => {                            
                            this.refresh();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                            this.afterDelete();
                            //this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            },
        );
    }

    afterDelete(): void {

    }
    //#endregion

    //#region Is modal
    saveSelected() {
        if (this.isGetFullObj) {
            this.modal.close(this.selectedDataItems);
        }
        else {
            const ids = this.selectedDataItems.map(m => m['id']);
            this.modal.close(ids);
        }

    }

    successForControl(result: any, displayText: string) {
        let res = Object.assign(
            {
                ...result,
            },
            {
                value: result.id,
                displayText: displayText,
                data: result,
                fts: AppUtilityService.getFullTextSearch(AppUtilityService.isNullOrEmpty(displayText) ? '' : displayText),
            },
        );
        return res;
    }

    close() {
        this.modal.destroy();
    }


    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header'
        });

    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();;
    }
    //#endregion

}
