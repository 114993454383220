import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from '@env/environment';
import { FirebaseMobileDto, TaiKhoanManagementServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable({
    providedIn: 'root'
})
export class NotificationFirebaseService {

    constructor(private afMessaging: AngularFireMessaging,
        // private db: AngularFireDatabase,
        // private appSession: AppSessionService,
        // private afAuth: AngularFireAuth,
        private accountSp: TaiKhoanManagementServiceProxy
    ) {
        // this.afMessaging.onBackgroundMessage(payload => {
        //     console.log('Background message received.', payload);
        //     // You can use this to update the UI or store the message in an array for later display.
        //   });

    }

    init() {
        this.requestToken();
        //this.requestPermission();
        //this.listen();

        abp.event.on('NotificationFirebaseService', message => {
            console.log(message, 'quyet');
        });
    }

    requestToken() {
        this.afMessaging.requestToken
            .subscribe(
                (token) => {
                    if (token) {
                        if (!environment.production) {
                            //console.log('Permission granted! Save to the server!', token);
                        }
                        const input = new FirebaseMobileDto();
                        input.token = token;
                        input.deviceId = 'web-app';
                        this.accountSp.registerFirebaseToken(input).subscribe();
                    }
                },
                (error) => {
                    console.error(error);
                },
            );
    }

    requestPermission() {
        this.afMessaging.requestPermission
            .subscribe(
                (res) => {
                    console.log(res, 'Notification permission granted.');
                },
                (error) => {
                    console.error(error);
                },
            );
    }

    listen() {
        this.afMessaging.messages
            .subscribe((message: any) => {
                console.log(message, 'thong bao');
            });
    }


}
