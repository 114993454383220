import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

import { UtilsModule } from '@shared/utils/utils.module';

import { AppCommonModule } from '@app/shared/common/app-common.module';

import { default as ngLang } from '@angular/common/locales/vi';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { LanguageSwitchComponent } from '@app/shared/layout/layout-header/components/language-switch.component';
import { HeaderUserComponent } from '@app/shared/layout/layout-header/components/user.component';
import { HeaderComponent } from '@app/shared/layout/layout-header/header.component';
import { SidebarComponent } from '@app/shared/layout/layout-sidebar/sidebar.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { SmsVerificationModalComponent } from '@app/shared/layout/profile/sms-verification-modal.component';
import { LoadingModule } from '@delon/abc/loading';
import { DELON_LOCALE, en_US as delonLang } from '@delon/theme';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { LayoutDefaultModule } from '@node_modules/@delon/theme/layout-default';
import { LayoutModule } from '@shared/layout/layout.module';
import { SharedModule } from '@shared/shared.module';
import { HotkeyModule } from 'angular2-hotkeys';
import { vi as dateLang } from 'date-fns/locale';
import { NZ_DATE_LOCALE, NZ_I18N, vi_VN as zorroLang } from 'ng-zorro-antd/i18n';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImpersonationService } from './pages/admin/users/impersonation.service';
import { NotificationStateService } from './pages/he-thong/user-notification/service/notification-state.service';
import { SessionTimeoutModalComponent } from './shared/common/session-timeout/session-timeout-modal-component';
import { SessionTimeoutComponent } from './shared/common/session-timeout/session-timeout.component';
import { PipeSharedModule } from './shared/customize-comp/pipe/Pipe-Shared.module';
import { ClickOutSideDirective } from './shared/data-comp/directive/clickOutSide.directive';
import { FooterComponent } from './shared/layout/layout-footer/footer.component';
import { SysChatMessageInboxComponent } from './shared/layout/layout-header/components/sys-chat-message-inbox/sys-chat-message-inbox.component';
import { SysNotificationComponent } from './shared/layout/layout-header/components/sys-notification/sys-notification.component';
import { UserAttendanceComponent } from './shared/layout/layout-header/components/user-attendanced.component';
import { NavbarComponent } from './shared/layout/layout-navbar/navbar.component';
import { SideBarMenuComponent } from './shared/layout/layout-sidebar-custom/side-bar-menu.component';
import { HeaderNotificationsComponent } from './shared/layout/notifications/header-notifications.component';
import { NotificationSettingsModalComponent } from './shared/layout/notifications/notification-settings-modal.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { ChatSignalrService } from './shared/services/chat-signalr.service';
import { AppConsts } from '@shared/AppConsts';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  listPlugin
]);

const LANG = {
    abbr: 'vi',
    ng: ngLang,
    zorro: zorroLang,
    date: dateLang,
    delon: delonLang,
};
const LANG_PROVIDES = [
    { provide: LOCALE_ID, useValue: LANG.abbr },
    { provide: NZ_I18N, useValue: LANG.zorro },
    { provide: NZ_DATE_LOCALE, useValue: LANG.date },
    { provide: DELON_LOCALE, useValue: LANG.delon },
];

const NAVCOMPONENTS = [
    HeaderComponent,
    SidebarComponent,
    SideBarMenuComponent,
    NavbarComponent,
    HeaderUserComponent,
    LanguageSwitchComponent,
    HeaderNotificationsComponent,
    FooterComponent,
    UserAttendanceComponent,
    SysNotificationComponent,
    SysChatMessageInboxComponent,
];

@NgModule({
    imports: [BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ImageCropperModule,
        LayoutModule,
        LayoutDefaultModule,
        LoadingModule,
        HotkeyModule.forRoot(),
        ColorPickerModule,
        AngularFireModule.initializeApp(AppConsts.fireBase),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        PipeSharedModule,
        FullCalendarModule // register FullCalendar with your app
    ],
    declarations: [
        AppComponent,
        ...NAVCOMPONENTS,
        LoginAttemptsModalComponent,
        ChangePasswordModalComponent,
        MySettingsModalComponent,
        ChangeProfilePictureModalComponent,
        SmsVerificationModalComponent,
        NotificationsComponent,
        NotificationSettingsModalComponent,
        SessionTimeoutModalComponent,
        SessionTimeoutComponent,
        ClickOutSideDirective,
    ],
    entryComponents: [
        LoginAttemptsModalComponent,
        ChangePasswordModalComponent,
        MySettingsModalComponent,
        ChangeProfilePictureModalComponent,
        SmsVerificationModalComponent,
        NotificationSettingsModalComponent
    ],
    providers: [UserNotificationHelper, ImpersonationService,
        ChatSignalrService, NotificationStateService,
        ...LANG_PROVIDES],
})

export class AppModule {
}
