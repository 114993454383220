import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';
import { AppSessionService } from '@shared/common/session/app-session.service';

// @Injectable()
@Injectable({
    providedIn: 'root',
})
export class ChatSignalrService  {
    appSessionService: AppSessionService;
    constructor(
        injector: Injector,
        public _zone: NgZone
    ) {
        this.appSessionService = injector.get(AppSessionService);
        //super(injector);
    }

    chatHub: HubConnection;
    isChatConnected = false;

   configureConnection(connection): void {
        // Set the common hub
        this.chatHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    reject();
                } else {
                    connection.start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isChatConnected = false;

            if (e) {
                abp.log.debug('Chat connection closed with error: ' + e);
            } else {
                abp.log.debug('Chat disconnected');
            }

            start().then(() => {
                this.isChatConnected = true;
            });
        });

        // Register to get notifications
        this.registerChatEvents(connection);
    }

    registerChatEvents(connection): void {
        connection.on('getChatMessage', message => {
            abp.event.trigger('app.chat.messageReceived', message);
        });

        connection.on('getSupervisionNotification', message => {
            abp.event.trigger('app.supervisionNotification', message);
        });

        connection.on('getSystemNotification', message => {
            abp.event.trigger('app.getSystemNotification', message);
        });

        connection.on('systemChatRoomChange-'+ this.appSessionService.user.id, message => {
            abp.event.trigger('app.systemChatRoomChange', message);
        });

        connection.on('systemScormCompleted-'+ this.appSessionService.user.id, message => {
            abp.event.trigger('app.systemScormCompleted', message);
        });
    }

    sendMessage(messageData, callback): void {
        if (!this.isChatConnected) {
            if (callback) {
                callback();
            }

            //abp.notify.warn(this.l('ChatIsNotConnectedWarning'));
            return;
        }

        this.chatHub.invoke('sendMessage', messageData).then(result => {
            if (result) {
                abp.notify.warn(result);
            }

            if (callback) {
                callback();
            }
        }).catch(error => {
            abp.log.error(error);

            if (callback) {
                callback();
            }
        });
    }

    init(): void {
        this._zone.runOutsideAngular(() => {
            abp.signalr.connect();
            abp.signalr.startConnection(abp.appPath + 'signalr-myChatHub', connection => {
                this.configureConnection(connection);
            }).then(() => {
                abp.event.trigger('app.chat.connected');
                this.isChatConnected = true;
            });
            //
            // this.notificationHubConnection = new signalR.HubConnectionBuilder()
            // .withUrl(environment.apiHost + '/notificationsHub')
            // .withAutomaticReconnect()
            // .build();
            //
        });
    }
}
