import { NgModule } from '@angular/core';
import { SafePipe } from '@app/shared/customize-comp/pipe/SafePipe';
import { XoaTextHuyenPipe } from '@app/shared/customize-comp/pipe/xoa-text-huyen';
import { XoaTextXaPipe } from '@app/shared/customize-comp/pipe/xoa-text-xa';
import { AbsPipe } from './abs.pipe';
import { CutStringPipe } from './cut-string.pipe';
import { formatBytesPipe } from './formatBytes.pipe';
import { IndexToOrderCharPipe } from './index-to-order-char.pipe';
import { OraArrayOrder } from './ora-array-order.pipe';
import { OraNumberStrPipe } from './ora-numberStrPipe';
import { oraPaging } from './ora-paging.pipe';
import { ReplaceSomeTextPipe } from './replace-some-text.pipe';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import { StringToObjPipe } from './string-to-obj.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { UppercaseFirstLetterPipe } from './uppercase-first-letter.pipe';
import { UppercaseFirstWordPipe } from './uppercase-first-word.pipe';
import { XoaTextTinhPipe } from './xoa-text-tinh';


const pipe = [
    SafePipe,
    XoaTextXaPipe,
    XoaTextHuyenPipe,
    XoaTextTinhPipe,
    OraNumberStrPipe,
    UppercaseFirstWordPipe,
    UppercaseFirstLetterPipe,
    StringToObjPipe,
    AbsPipe,
    ReplaceSomeTextPipe,
    oraPaging,
    OraArrayOrder,
    formatBytesPipe,
    TimeAgoPipe,
    CutStringPipe,
    SecondsToTimePipe,
    IndexToOrderCharPipe,
]

@NgModule({
    declarations: [...pipe],
    exports: [...pipe ],

})
export class PipeSharedModule {

}

