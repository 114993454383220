import { Subject } from 'rxjs';
export class NotificationStateService {

    //isReloadNotification$
    isReloadNotification$ = new Subject<boolean>();
    setIsReloadNotification$(value: boolean) {
        this.isReloadNotification$.next(value);
    }

}
