<a nz-dropdown nzTrigger="click" class="position-relative" [nzDropdownMenu]="menu">
    <nz-badge nzSize="small" [nzCount]="countNumber">
        <div style="height: 20px; width: 20px; cursor: pointer">
            <i style="color: #608180" [ngClass]="countNumber > 0 ? 'active' : ''" class="bell far fa-bell"></i>
        </div>
    </nz-badge>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="header-notification-box">
        <div class="noti-box-content">
            <div style="height: 50px; padding: 20px; font-size: 18px">{{ 'Notifications' | localize }}</div>
            <hr />
            <div *ngIf="listItem?.length == 0" style="height: 300px"
                class="w-100 d-flex flex-column justify-content-center">
                <nz-empty [nzNotFoundContent]="'ThereIsNoNotification' | localize"></nz-empty>
            </div>
            <div *ngIf="listItem?.length > 0" style="height: 300px" class="custom-scroll w-100">
                <div *ngFor="let item of listItem" [ngStyle]="{'background-color': (item.status == Sys_NotificationProcessStatus.Unread || item.status == null) ? '#e0e0e0' : ''}">
                    <div class="noti d-flex" [ngClass]="(item.status == Sys_NotificationProcessStatus.Unread || item.status == null) ? 'noti-new' : ''"
                        (click)="loadStatus(item)">
                        <div class="col-2">
                            <div style="height: 40px; width: 40px; border-radius: 50%;margin-top:1rem" >
                                <img style="height: 100%;
                                width: 100%;
                                border-radius: 50%;
                                border: 1px solid #e0e0e0;" *ngIf="item.avatar; else withOutAvatar" [src]="AppConsts.getBaseUrlMinio() +item.avatar" />
                                <ng-template #withOutAvatar>
                                    <div style="
                                            background-color: rgb(255, 214, 80);
                                            height: 100%;
                                            width: 100%;
                                            border-radius: 50%;
                                            padding: 10px;
                                        " class="d-flex justify-content-center align-items-center">
                                        <i style="color: #fff; font-size: 25px" class="fas fa-user-circle"></i>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-10" style="cursor: pointer;">{{ item.subject }}</div>
                    </div>
                    <div style="cursor: default; font-size: 12px; width: 96%" class="d-flex justify-content-end">
                        <i style="font-size:14px; line-height: 18px;" class="far fa-clock"></i> <span class="ml-1">{{
                            item.timeAgo }}</span>
                    </div>
                    <hr />
                </div>
            </div>
            <hr />
            <div class="d-flex justify-content-center align-items-center pb-3">
                <button style="width: 90%" nz-button nzType="primary" (click)="viewAllNotification()">
                    {{ 'SeeAllNotifications' | localize }}
                </button>
            </div>
        </div>
    </div>
</nz-dropdown-menu>
