<layout-header class="menu__header" >
</layout-header>
<layout-sidebar-custom></layout-sidebar-custom>
<section style="position:relative;padding-bottom:20px;" class="default__content" [ngStyle]="{'paddingLeft': isPinSidebar == false ? '70px' : '264px'}">
    <section class="fullscreen">
        <!-- Render manual -->
        <router-outlet></router-outlet>
    </section>
    <layout-footer></layout-footer>
</section>
<ng-template #settingHost></ng-template>
<nz-back-top></nz-back-top>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>
<!--<layout-theme-btn></layout-theme-btn>-->
